import React from 'react';
import './About.css';
import about from '../../Assets/c13.jpeg';



const About = () => {
  return (
    <>
    <div className='a-all'>
        <div className='a-image'>
            <div className='g-container'></div>
            <img src={about} />

        </div>

        <div className='a-text'>
             <h1>About Us</h1>
             <p>
             Nexinfra Solutions is your trusted partner in warehousing, industrial construction, and real estate solutions. With a steadfast commitment to excellence, innovation, and client satisfaction, we strive to deliver superior services and properties tailored to meet the diverse needs of businesses across various industries.
             </p>
             <p>We specialize in providing comprehensive solutions for warehousing, industrial construction, and real estate development projects. From designing and constructing state-of-the-art facilities to offering leasing and property management services, we are dedicated to delivering value-added solutions that drive success for our clients.
             </p>
             <p>We leverage our industry expertise, innovative technologies, and strategic partnerships to ensure the success of every project, from inception to completion.</p>
           
             <a href='/Contact'><button className='h-button'>Contact Us</button></a>
             
               <br/>
               <br/>
            
        </div>
    </div>
    </>
  )
}

export default About