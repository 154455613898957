import React from 'react';
import './Team.css';

import img09 from '../../Assets/f1.jpeg'

const Team = () => {
  return (
    <>
    <br/>
    <br/>
    <div className='te-header'>
      <h1>Our <span style={{color:'#0d6efdcc'}}>Team</span></h1>
    </div>

    <>
    <div className='a-all'>
        <div className='a-image'>
            <div className='g-container'></div>
            <img style={{height:'460px', width:'360px'}} src={img09} />

        </div>

        <div className='a-text'>
        <br/>
        <br/>
        <br/>
             <p><span style={{fontWeight: 'bold'}}>Mrs. Divya Chandradhara,</span> is a serial Entrepreneur with Msc & MSA in Marketing Management. Divya is currently the CEO of Nexinfra. Ms. Divya is having several years of experience in Real Estate Consultancy has manged setting up several companies and consulting several companies. Ms. Divya Chandradhara has received several awards like Top 100 hundred leaders of India.
             </p>
             <br/>
             <br/>
             <p>
             <span style={{fontWeight: 'bold'}}>Mr.S.V.Chidambaresh,</span> a M.Tech graduate from IIT Madras in the year 1985 with a vision of providing complete solution for structural and architectural designs/Project Management for Residential, Commercial, Industrial and Infrastructure projects. Chidambaresh excellence in providing complete solutions to structural and architectural designs/Project Management for Residential, Commercial, Industrial and Infrastructure             
             </p>
           
             
             
               <br/>
               <br/>
            
        </div>
    </div>
    </>
    
 </>
  )
}

export default Team