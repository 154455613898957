import {React,useEffect} from 'react';
import './Service2.css';

import img0001 from '../../Assets/c13.jpeg'
import img0002 from '../../Assets/pr1.jpg'
import img0003 from '../../Assets/pr2.jpeg'
import img0004 from '../../Assets/pr3.jpg'
import img0005 from '../../Assets/pr4.jpg'
import img0006 from '../../Assets/pr5.jpg'
import img0007 from '../../Assets/c15.jpeg'

import img0008 from '../../Assets/c16.jpeg'

import img0009 from '../../Assets/c17.jpeg'




const Service2 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
        
      
        <div className='c-banner'>
        <h1>Our Services</h1>
       </div>
       <br/>
     

       <div className='a1-all'>
        <div className='a1-text'>
            <br/>
            <br/>
        <h1>Industrial Land / Warehouse Services</h1>
        <br></br>
             <h4>
             Our services encompass the comprehensive construction of industrial warehouses, tailored to meet distinct grading standards. We specialize in designing and building warehouses that align with specific grading criteria, such as Class A, Class B, and Class C, ensuring that each facility meets the highest standards of functionality, efficiency, and safety.
             </h4>
             <h4>
             In addition to constructing industrial warehouses, we offer full-service assistance in the rental process. For landlords, we provide support in marketing their warehouse spaces, identifying suitable tenants, and negotiating lease agreements. We leverage our industry expertise and network to attract prospective tenants and maximize rental income for our clients.
             </h4>
             <h4>
             For tenants seeking warehouse space, we offer personalized assistance in finding the ideal facility to meet their specific needs and requirements. Our team works closely with tenants to understand their business objectives and preferences, guiding them through the leasing process and helping them secure the most suitable warehouse space for their operations.
             </h4>             
               <br/>
               <br/>
        </div>
        <div className='a1-image'>
            <div className='g-container'></div>
            <img src={img0009} />

        </div>
    </div>


    
        <br/>
        <br/>

    <div className='s-container'>

        
        <div className='left'>
             <div className='left1'>
                <img src={img0001}/>
                <h1>Residential Real Estate</h1>
                <hr className='s-hr'/>
                <br/>
                <h4>Whether youre buying, selling, or renting a home, our seasoned real estate
professionals are here to guide you through every step of the process. We ensure that
your residential real estate transactions are smooth, efficient, and tailored to your
specific requirements.</h4>
             </div>
   
<br/>
<br/>

             <div className='left1'>
             <img src={img0002}/>
                <h1>Investment Consultation:</h1>
                <hr className='s-hr'/>
                <br/>
                
                <h4>Looking to invest in real estate? Our experienced consultants provide in-depth market
analyses and investment strategies tailored to your financial goals. Whether it's
residential properties, commercial spaces, or real estate portfolios, we help you make
informed decisions for long-term success.</h4>
             </div>
             <br/>
             <br/>
             <div className='left1'>
             <img src={img0003}/>
                        <h1>Real Estate Development</h1>
                        <hr className='s-hr'/>
                        <br/>
                        
                        <h4>As a full-service real estate company, we engage in real estate development projects.
From conceptualization to construction management, we bring a wealth of experience
to the table, ensuring the successful realization of your development goals.</h4>
                    </div>
<br/>
<br/>
                    
                    
        </div>

        <div className='left'>
        <div className='left1'>
                    <img src={img0004}/>
                        <h3>Market Insights and Advisory</h3>
                        <hr className='s-hr'/>
                        <br/>
                        
                        <h4>Our integrated platform provides clients with market insights and advisory services.
Stay informed with the latest trends, investment opportunities, and regulatory changes
that may impact your real estate decisions.</h4>
                    </div>
   


            
             
<br/>
<br/>
<div className='right1'>
                    <img src={img0007}/>
                        <h1>Property Management</h1>
                        <hr className='s-hr' />
                        <br/>
                        
                        <h4>Our property management services go beyond the transaction. We specialize in
managing residential and commercial properties, ensuring that your investments are
not only secure but also optimized for growth. From tenant screening to maintenance
coordination, we handle the details so you can enjoy the benefits of property
ownership.</h4>
<br/>
                    </div>
                    <br/>
                    <br/>

                   

                   
                    
                    
        </div>

        <div className='right'>
                <div className='right1'>
                <img src={img0005}/>
                <h1>Real Estate Consulting </h1>
                        <hr className='s-hr'/>
                        <br/>
                        <h4>Custom advice for land developers, home builders, multifamily developers and those who invest in those projects and companies.</h4>
                    </div>
<br/>
<br/>
                    <div className='right1'>
                    <img src={img0006}/>
                        <h1>Commercial Real Estate</h1>
                        <hr className='s-hr' />
                        
                        <br/>
                        <h4>For businesses seeking the perfect space, we offer a comprehensive suite of services
in commercial real estate. From locating strategic commercial properties to

negotiating leases and sales, we are committed to helping your business thrive in the
right environment.</h4>
                    </div>
                    <br/>
                    <br/>
                   
                   
                    
                    
        </div>
        </div>
    </>
  )
}

export default Service2