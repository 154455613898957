import React from 'react';
import './Team2.css';
import imgg1 from '../../Assets/f1.jpeg';

const Team2 = () => {
  return (
    <>

    <div className='c-banner'>
        <h1>Our Team</h1>
       </div>
    <br/>
    <br/>
    
    <div className='te-all'>
    <div className='te-photo'>
          <img src={imgg1}/>
         
      </div>
    
   
      <div className='te-des'>
          <p>
         <span style={{fontWeight:'bold'}}> Mrs. Divya Chandradhara</span> , is a serial Entrepreneur with Msc & MSA in Marketing Management.
Divya is currently the CEO of Nexinfra. Ms. Divya is having several years of experience in Real Estate Consultancy has manged setting up several companies and consulting several companies. Ms. Divya Chandradhara has received several awards like Top 100 hundred leaders of India. Prior to joining BioAgile Therapeutics, Divya has been associated with Advinus Therapeutics, TATA group handling business for domestic and International market. 
  <br/>
  <br/>
  
  <br/>

  
  <span style={{fontWeight:'bold'}}> Mr.S.V.Chidambaresh</span> a M.Tech graduate from IIT Madras in the year 1985 with a vision of providing complete solution for structural and architectural designs/Project Management for Residential, Commercial, Industrial and Infrastructure projects. Chidambaresh excellence in providing complete solutions to structural and architectural designs/Project Management for Residential, Commercial, Industrial and Infrastructure
  <br/>
  <br/>


          </p>


          
      </div>
      



    </div>
    <div className='te-all'>
      
      

      
   
      
     
     



    </div>
 </>
  )
}

export default Team2