import React from 'react';
import './Footer1.css';

import social1 from '../../Assets/facebook.png';
import social2 from '../../Assets/twitter.png';
import social3 from '../../Assets/whatsapp.png';
import social4 from '../../Assets/instagram.png';
import logo1 from '../../Assets/logof.png';


const Footer1 = () => {
  return (
    <>
    <div className='f1-all'>
     <div className='f-left'>
         
         <div className='logo1'>
            <img src={logo1}/>
         </div>
     

         <h4 style={{color:'#0d6efdcc'}}>Address :</h4>
         <h6>#2/5, Dahlia Building, 3rd Floor,</h6>
         <h6> 80 Feet Road, RMV 2nd Stage,</h6>
         <h6>Bengaluru ,Karnataka  560094</h6>
         
         <br/>
         <h4 style={{color:'#0d6efdcc'}}>Landline :</h4>
         <h6>080-4374520</h6>
         <br/>
         <h4 style={{color:'#0d6efdcc'}}>Phone No :</h4>
         <h6>9538961761</h6>
         <br/>
         <h4 style={{color:'#0d6efdcc'}}>Mail Id :</h4>
         <h6>info@nextinfras.com<br/>divya@nexinfras.com</h6>
         <br/>
         <br/>
        {/* <div className='social-all'>
        <a>
            <div classname='social'>
                 <img style={{width:'50px',paddingLeft:'8px'}}  src={social1}/>
            </div>
         </a>
         <a>
            <div classname='social'>
            <img style={{width:'50px',paddingLeft:'8px'}} src={social2}/>
                
            </div>
         </a>
         <a>
            <div classname='social'>
            <img style={{width:'50px',paddingLeft:'8px'}}  src={social3}/>
            </div>
         </a>
         <a>
            <div classname='social'>
            <img style={{width:'50px',paddingLeft:'8px'}}  src={social4}/>
            </div>
         </a>

        </div> */}
        <br/>
         
     </div>
     <div className='F-center'>
     <div className='f-right'>
            
     <iframe  className='map22' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.1269979359695!2d77.5766422!3d13.027583699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17e1770bc3b5%3A0x589e70b090a9d1de!2sBioAgile%20Therapeutics%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1707993806636!5m2!1sen!2sin" 
     
       
       >
        
      </iframe>
      </div>
      </div>
      </div>
   

     

    </>
  )
}

export default Footer1