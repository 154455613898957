import React from 'react';
import './Client.css';

import img1 from '../../Assets/l1.jpeg';
import img2 from '../../Assets/l2.png';
import img3 from '../../Assets/l3.png';
import img4 from '../../Assets/l4.png';
import img5 from '../../Assets/l5.png';

const Client = () => {
  return (
    <>
    <br/>
    <br/>
    <div style={{backgroundColor:  '#F8F9FA' }}>
    <div className='c1'>
        <h1>Our <span style={{color:'#0d6efdcc'}}>Clients</span></h1>

    </div>
    <br/>
  <div className='cimg1-all'>
    <div className='cimg-all'>
        {/* <div className='imgc'>
            <img src={img1}/>
        </div> */}

        <div className='imgc'>
        <img src={img2}/>
            </div>

            <div className='imgc'>
            <img src={img3}/>
            </div>

            <div className='imgc'>
            <img src={img4}/>
            </div>
            <div className='imgc'>
            <img src={img5}/>
            </div>
    </div>
    <br/>
    <br/>
    
    </div>
    </div>
    <br/>

    </>
  )
}

export default Client