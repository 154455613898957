import React from 'react';
import './Footer.css';


import social1 from '../../Assets/facebook.png';
import social2 from '../../Assets/twitter.png';
import social3 from '../../Assets/whatsapp.png';
import social4 from '../../Assets/instagram.png';

const Footer2 = () => {
  return (
    <>
    <div className='f2-all'>
   
        <div className='rights1'>
        <p className='text-center text-white fs-5'>
        © 2024 Nexinfra Solutions <a href='https://rcsinfo.in/' className='text-white'>. Powered By RCS Technology</a>
                        </p>
            
        </div>
    </div>
    </>
  )
}

export default Footer2